import AttachIcon from '../icons/attach_1.svg';
import SendIcon from '../icons/send.svg';

const tui_editor = (theme) => {
	return {
		'.tui-toolbar-button-attach': {
			backgroundImage: `url(${AttachIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundColor: '#f7f9fc',
      backgroundSize: '23px 32px',
		},
		'.tui-toolbar-button-send': {
			backgroundImage: `url(${SendIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundColor: '#f7f9fc',
      backgroundSize: '23px 32px',
		},
	};
};

export default tui_editor;
