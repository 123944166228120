// React core
import React from 'react';

// redux && redux store
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { history, store, persistor } from './store.js';
import { LocalizeProvider } from 'react-localize-redux';

// Our history
import { ConnectedRouter } from 'connected-react-router';

// Our "pages" main components
import Main from './Main';
// import { StylesProvider } from '@material-ui/core/styles';
// import '../node_modules/font-awesome/css/font-awesome.min.css';

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<LocalizeProvider store={store}>
						<Main />
					</LocalizeProvider>
				</ConnectedRouter>
			</PersistGate>
		</Provider>
	);
}

export default App;
