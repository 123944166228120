import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

import { NavBar, Footer, MessageToast } from './';
import { Container } from '@mui/material';
import PrivateRoute from '../../helpers/PrivateRoute';
import { Loading, Modal, NotificationsModal } from '../Generic';
import { setMenuAction, closePlayerAction } from '../Generic/actions/genericActions';
import ErrorBoundary from './ErrorBoundary';
import Progress from './Progress';
import BlockUI from './BlockUI';
import ImageDisplayCards from './ImageDisplayCards';
import Player from './Player';

import SocketProvider from '../Chat/SocketProvider';
import { NOTIFICATIONS } from '../../helpers/webSocketChannels';
import MESSAGES from '../../helpers/messages';

class DefaultLayout extends Component {
	componentDidMount() {
		if (this.props.menu) {
			this.props.setMenuAction(this.props.menu);
		}
	}

	componentDidUpdate() {
		if (!this.props.lightbox.open) {
			Object.assign(document.body.style, { overflow: 'auto', margin: '0px', paddingRight: '0px' });
		}
	}

  handleFilterMessages = (data) => {
		return data.type === 'notification' && data.link_type !== 'read' && (this.props.user.scopes.includes('lists.cross_client') || data.ticket_client_id == this.props.user.current_client);   
	};


	render() {
		if (this.props.menu) {
			this.props.setMenuAction(this.props.menu);
		}
	}

	render() {
		const { dispatch, component: Component, path, extraProps, ...rest } = this.props;

		return rest.isLoggedIn ? (
      <SocketProvider
				channel_name={NOTIFICATIONS}
				channel={typeof rest.channel.name !== 'undefined' ? rest.channel.name : ''}
				handleFilterMessages={this.handleFilterMessages}
        skip_history={true}
			>
			<div className={"generic-defaultlayout-main " + (this.props.backgroundColorClass ? this.props.backgroundColorClass : '')}>
				{!rest.disableNavigation && <NavBar></NavBar>}
				{rest.loading ? (
					<Loading />
				) : (
					<React.Fragment>
						<Container
							component="main"
							maxWidth={this.props.fullsizeContainer ? false : 'xl'}
							{...(!this.props.disableMargins && { className: 'generic-defaultlayout-content' })}
							disableGutters={this.props.disableGutters}
						>
							<ErrorBoundary>
								<PrivateRoute {...rest} exact path={path} extraProps={extraProps} component={Component} />
							</ErrorBoundary>
						</Container>
					</React.Fragment>
				)}
				<BlockUI blocking={true} />
				<MessageToast />
				<Modal />
				<NotificationsModal />
				<Progress />
				{rest.lightbox.open || rest.player.open ? (
					<React.Fragment>
						<div style={{ display: 'none' }}>
							{rest.lightbox.open && rest.lightbox.image ? (
								<ImageDisplayCards
									id={'question_' + this.props.question_index}
									initialFiles={rest.lightbox.image}
									limitFiles={1}
									lightboxOnly={true}
								/>
							) : null}
							{rest.player.open && rest.player.url ? (
								<Player open={rest.player.open} url={rest.player.url} onClose={() => this.props.closePlayerAction()} />
							) : null}
						</div>
					</React.Fragment>
				) : null}
				{!rest.disableNavigation && <Footer></Footer>}
			</div>
      </SocketProvider>
		) : (
			<Redirect to="/login" />
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.generic.loading,
	lightbox: state.generic.lightbox,
	player: state.generic.player,
	isLoggedIn: state.login.login,
  channel : state.users.whoami.channel,
  user : state.users.whoami,
});

export default connect(mapStateToProps, {
	setMenuAction,
	closePlayerAction,
})(DefaultLayout);
