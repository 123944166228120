import React, { Component } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Dialog, DialogTitle, DialogContent, Button, Typography } from '@mui/material';
import { displayNotificationsModalAction } from './actions/genericActions';
import { NotificationsTable } from '.';
import { getTranslate } from 'react-localize-redux';
import { NOTIFICATIONS } from '../../helpers/webSocketChannels';
import { Alert } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

class NotificationsModal extends Component {
	render() {
		const { translate } = this.props;

		return (
            <Dialog
				scroll="body"
				maxWidth="xl"
				open={this.props.notifications_modal.open}
				onClose={() => this.props.displayNotificationsModalAction(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				// fullWidth
			>
				<DialogTitle>
					<Typography color="primary" component="div" variant="h6">
						{translate('notifications_modal_title')}
					</Typography>
					<IconButton
                        aria-label="close"
                        className="notification-modal-close-icon"
                        onClick={() => this.props.displayNotificationsModalAction(false)}
                        size="large">
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					{this.props.channel.length > 0 && this.props.connection_date == null ? (
						<Alert
							
							variant="outlined"
							severity="warning"
							action={
								<Button
									onClick={() => {
										window.location.reload();
									}}
									variant="body2"
									startIcon={<RefreshIcon />}
								/>
							}
						>
							{this.props.translate('websocket_failed_conection')}
						</Alert>
					) : (
						<NotificationsTable />
					)}
				</DialogContent>
			</Dialog>
        );
	}
}

const mapStateToProps = (state) => ({
	notifications_modal: state.generic.notifications_modal,
	translate: getTranslate(state.localize),
	connection_date: state.websockets[NOTIFICATIONS].connection_date,
	channel: state.websockets[NOTIFICATIONS].channel,
});

export default connect(mapStateToProps, { displayNotificationsModalAction })(NotificationsModal);
