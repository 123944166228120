import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Badge, Link, Button } from '@mui/material';
import { AccountCircle as AccountCircleIcon, Notifications as NotificationsIcon, MoreVert as MoreIcon } from '@mui/icons-material';
import { NavBarLinks } from './NavBarLinks';
import { NavBarMenu } from './NavBarMenu';
import { NavBarMenuMobile } from './NavBarMenuMobile';
// import RenderNotificationsBell from './NotificationsBell';
import Notifications from './Notifications';

import { getTranslate } from 'react-localize-redux';

function NavBarFunction(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const [notificationsAnchorEl, setNotificationsAnchorEl] = React.useState(null);

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const isNotificationsMenuOpen = Boolean(notificationsAnchorEl);

	function handleProfileMenuOpen(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleMobileMenuClose() {
		setMobileMoreAnchorEl(null);
	}

	function handleMenuClose() {
		setAnchorEl(null);
		handleMobileMenuClose();
	}

	function handleMobileMenuOpen(event) {
		setMobileMoreAnchorEl(event.currentTarget);
	}

	function handleNotificationsMenuClose() {
		setNotificationsAnchorEl(null);
	}

	function handleNotificationsMenuOpen(event) {
		setNotificationsAnchorEl(event.currentTarget);
	}

	const menuId = 'primary-search-account-menu';

	const mobileMenuId = 'primary-search-account-menu-mobile';

	const notificationsId = 'notifications-bell';

	return (
        <AppBar
			variant={typeof props.settings.styling !== 'undefined' && !props.settings.styling.navbar_shadow ? 'outlined' : 'elevation'}
			className="generic-navbar-main"
			position="static"
		>
			<Toolbar variant="dense">
				<Link
					{...(!props.is_temporary_user && {
						component: RouterLink,
						to: '/dashboard',
					})}
					color="primary"
					underline="none"
				>
					<IconButton
                        disabled={props.is_temporary_user ? true : false}
                        edge="start"
                        className="generic-navbar-main-logo"
                        color="inherit"
                        aria-label="Menu"
                        size="large">
						<img id={'navbar-logo'} height="35" alt="Client logo" />
					</IconButton>
				</Link>

				<NavBarLinks
					translate={props.translate}
					navButtonActive={props.navButtonActive}
					scopes={props.scopes ? props.scopes : []}
					prompt={props.prompt}
					settings={props.settings}
					is_temporary_user={props.is_temporary_user}
				/>

				<div className="flexGrow" />

				{props.scopes && props.scopes.includes('translations.toggle') && (
					<Button variant="contained" color="primary" onClick={() => props.toggleTranslations()}>
						{props.translate('navbar_button_translations_toggle')}
					</Button>
				)}

				{typeof props.settings.feature !== 'undefined' && props.settings.feature.meetings && props.navButtonActive === 'meeting' && (
					<Button disabled className={'navbar-links-disabled-button generic-navbar-section-active'} color="inherit">
						{props.translate('navbar_button_disabled_meeting')}
					</Button>
				)}
				<div className={'generic-navbar-section-notifications'}>
					<Notifications
						menuId={notificationsId}
						isMenuOpen={isNotificationsMenuOpen}
						anchorEl={notificationsAnchorEl}
						handleMenuOpen={handleNotificationsMenuOpen}
						handleMenuClose={handleNotificationsMenuClose}
						translate={props.translate}
					/>
				</div>
				<div className="generic-navbar-section-desktop">
					<IconButton
						size={'small'}
						edge="end"
						aria-label="Account of current user"
						aria-controls={menuId}
						aria-haspopup="true"
						onClick={handleProfileMenuOpen}
						color="primary"
					>
						<AccountCircleIcon />
					</IconButton>
				</div>
				<div className="generic-navbar-section-mobile">
					<IconButton
						size={'small'}
						aria-label="Show more"
						aria-controls={mobileMenuId}
						aria-haspopup="true"
						onClick={handleMobileMenuOpen}
						color="primary"
					>
						<MoreIcon />
					</IconButton>
				</div>
			</Toolbar>

			<NavBarMenuMobile
				mobileMoreAnchorEl={mobileMoreAnchorEl}
				mobileMenuId={mobileMenuId}
				isMobileMenuOpen={isMobileMenuOpen}
				handleMobileMenuClose={handleMobileMenuClose}
				logout={props.logout}
				username={props.username}
				active={props.active}
				translate={props.translate}
				navButtonActive={props.navButtonActive}
				scopes={props.scopes ? props.scopes : []}
				prompt={props.prompt}
				settings={props.settings}
				is_temporary_user={props.is_temporary_user}
			/>

			<NavBarMenu
				menuId={menuId}
				anchorEl={anchorEl}
				isMenuOpen={isMenuOpen}
				handleMenuClose={handleMenuClose}
				logout={props.logout}
				username={props.username}
				active={props.active}
				translate={props.translate}
				scopes={props.scopes ? props.scopes : []}
				settings={props.settings}
			/>
		</AppBar>
    );
}

const mapStateToProps = (state) => ({
	scopes: state.users.whoami.scopes,
	username: state.users.whoami.name,
	active: state.users.whoami.onduty,
	translate: getTranslate(state.localize),
	prompt: state.prompt,
	settings: state.settings,
	is_temporary_user: state.users.whoami.status == 5 || state.users.whoami.status == 6,
});

export default connect(mapStateToProps)(NavBarFunction);
